import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useState,useContext,useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import prod_chain1 from '../assets/images/prod_chain1.png';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import AuthContext from "./Usecontexts/Maincontext";


const Product = ({name,image,description,description_small}) => {

    const theme= useTheme()
    const {currentlang}= useContext(AuthContext)

    const islg= useMediaQuery(theme.breakpoints.up('lg'))
    const isxl= useMediaQuery(theme.breakpoints.up('xl'))
    const ismd= useMediaQuery(theme.breakpoints.up('md'))
    const issm= useMediaQuery(theme.breakpoints.up('sm'))

    return (

       

        <Grid className={!islg?"shake": 'none'} xs={5} md={4} container style={{
            display:'flex',
            paddingTop:'20px',
            paddingLeft:!islg?'10px':'20px',
            paddingRight:!islg?'10px':'20px',
            paddingBottom:'20px',
            alignItems:'flex-start',
            gap:'5px',
            height:isxl? '350px' : islg? '430px' : ismd? '430px' : issm? '300px' : '350px',
            borderRadius:"10px",
            borderBottom:"4px solid #5E3BEE",
            backgroundColor:"#F5FCFF",
            boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
            margin:'10px',
            cursor:'pointer',
            
            


            
            
            
            
        }}>

            <Grid xs={12} container style={{

                display:'flex',
                alignItems:'flex-start',
                flexDirection:'row',
                gap:'22px',
                
            }}

            >

                <Grid xs={3} style={{
                    display:'flex',
                    padding:'10px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'14px',
                    borderRadius:'10px',
                    backgroundColor:"#FFF",
                    boxShadow:"0px 0px 16px 0px rgba(0, 0, 0, 0.10)",
                    
                }}>

                    <img src={image} alt="symbol" style={{width:isxl?'50px':'30px',height:isxl?'50px':'30px',objectFit:'cover'}}/>


                </Grid>

                </Grid>

                <Grid xs={12} style={{ flexDirection:'column', justifyContent:'flex-start' ,alignItems:'flex-start', alignSelf:'stretch', gap:'21px', height:"100%",display:'flex'}} >

                    <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', fontSize:!islg?'20px':'18px', fontWeight: currentlang==='he'? 700: 600, fontStyle:'normal',lineHeight:"150%"}}>

                    {name}
                    </div>

                    <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', textAlign:currentlang==='he'?'right':'left', fontSize:!islg?'16px': '15px', fontWeight:400, fontStyle:'normal',lineHeight:"150%"}}>

                        
                    {!ismd? description_small: description}

                    </div>
                    
                    

                  
                </Grid>
                


            </Grid>



      


    )
}

export default Product;